<template>
  <div id="404" class="container">
    <div>
      <div class="header font-size-xl font-weight-bold mb-4 text-center">
        404
      </div>
      <div class="body font-size-big text-center">
        {{ $t("404.not_found") }}
      </div>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "404Show",
  setup(){
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("404.not_found"),
    })
  }
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 300px;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
